<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>

<script>

export default {
  name: "App",
  components: {

  },
};
</script>